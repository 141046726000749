
/* Modal */
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .modalContainer {
    max-width: 250px;
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
  }
    
  .modalRight {
    width: 100%;
  }
  
  .closeBtn {
    position: fixed;
    top: 8px;
    right: 8px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
  }
  
  .btnContainer {
    display: flex;
    padding: 1rem 1rem;
  }
  .btnContainer button {
    width: 100%;
    margin: .1rem;
    padding: 16px 0;
    /* border: none; */
    border: 1px solid #411b57;
    /* color: #ffffff; */
  }
  
  .btnPrimary {
    background-color: #411b57;
    color: white;
  }
  
  .btnOutline {
    /* background-color: #a76a99; */
    background-color: white;
    color: #411b57;
  }
  
  .bold {
    font-weight: 600;
  }
  
  @media screen and (max-width: 500px) {
      .modalContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 40%;
      }
      .heading {
        margin: 1rem;
      }
      .modalContainer {
        max-width: 250px;
        width: 100%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        background-color: #ffffff;
        box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
        border-radius: 8px;
      }
      
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 0.5rem;
        margin-top: 1rem;
        padding: 0.5rem 2rem;
      }

      .content h3 {
        font-size: 0.9rem;
      }

      .btnContainer {
        display: flex;
        padding: 0.5rem 1rem;
        justify-content: center ;
      }
      .btnContainer button {
        width: 70%;
        margin: .1rem;
        padding: 3px 0;
        border: 1px solid #411b57;
      }   
  }